/** HEADER **/
.header-login {
    display: none;
    height: 40px;
    line-height: 1;
    color: #777;
    align-content: center;

    @media (min-width: 768px) {
        display: flex;
        position: absolute;
        top: 50%;
        right: 100px;
        margin-top: -20px;
        border-right: solid 1px #dde0e2;
        padding-right: 35px;
    }

    &__link {
        display: flex;
        align-items: center;

        a {
            line-height: 40px;
            position: relative;
            padding-left: 35px;
            color: $color-light-gray;
            font-size: 11px;
            display: flex;
            align-items: center;


            i {
                color: $color-green;
                font-size: 24px;
                margin-right: 10px;
            }

            //&:before {
            //    position: absolute;
            //    top: 50%;
            //    transform: translateY(-50%);
            //    left: 0;
            //    font-size: 33px;
            //    color: $color-green;
            //    font-family: 'porto-icons';
            //}

            &:hover {
                text-decoration: none;
            }
        }
    }

}

.page-header.type16 .custom-block {
    @media (min-width: 768px) {
        left: 645px;
        right: auto;
        border-right: none;
        padding-right: 0px;
    }

    span {
        margin-top: 4px;
        color: $color-light-gray;
        display: block;
    }

    a {
        color: $color-light-gray;
        font-size: 18px;
        font-weight: 600;
        display: block;
        line-height: 27px;

        &:hover {
            text-decoration: none;
            color: $color-green;
        }
    }
}

.page-header.type16 .custom-block {
    @media (min-width: 768px) {
        left: 645px;
        right: auto;
        border-right: none;
        padding-right: 0px;
    }

    span {
        margin-top: 4px;
        color: $color-light-gray;
        display: block;
    }

    a {
        color: $color-light-gray;
        font-size: 18px;
        font-weight: 600;
        display: block;
        line-height: 27px;

        &:hover {
            text-decoration: none;
            color: $color-green;
        }
    }
}

.page-header.type16 .custom-block {
    @media (min-width: 768px) {
        left: 645px;
        right: auto;
        border-right: none;
        padding-right: 0px;
    }

    span {
        margin-top: 4px;
        color: $color-light-gray;
        display: block;
    }

    a {
        color: $color-light-gray;
        font-size: 18px;
        font-weight: 600;
        display: block;
        line-height: 27px;

        &:hover {
            text-decoration: none;
            color: $color-green;
        }
    }
}

.page-header.type16 .custom-block {
    @media (min-width: 768px) {
        left: 645px;
        right: auto;
        border-right: none;
        padding-right: 0px;
    }

    span {
        margin-top: 4px;
        color: $color-light-gray;
        display: block;
    }

    a {
        color: $color-light-gray;
        font-size: 18px;
        font-weight: 600;
        display: block;
        line-height: 27px;

        &:hover {
            text-decoration: none;
            color: $color-green;
        }
    }
}

.panel.header {
    @media (min-width: 768px) {
        display: none;
    }
}

.page-header.type16 .block-search {
    display: none;
    @media (min-width: 1200px) {
        display: block;
        margin-left: 10px !important;
    }
}


.header-cerkamed {
    &__right {
        flex-shrink: 0;
        flex-grow: 1
    }

    &__title {
        text-align: center;

        img {
            display: none;
            @media (min-width: 1200px) {
                display: inline;
            }
        }
    }

    &__search {
        position: relative;
    }
}
