/*** CHECKOUT PAGE ***/
.checkout-agreement {
    display: flex;
    flex-direction: column;
    position: relative;

    input {
        position: absolute;
        top: 3px;
        left: 0;
    }

    button {
        height: auto;
        text-align: left;

        span {
            text-align: left;
            font-size: 11px;
            color: $color-light-gray;
        }
    }

    label {
        position: relative;
        padding-left: 25px;

        &:after {
            position: absolute;
            top: 0;
            left: 15px;
            margin-left: 0;
        }
    }

    .mage-error {
        padding-left: 25px;
        font-size: 11px !important;
    }
}

.action-actions-toolbar--payment-data {
    margin-top: 10px;
}


[name*="shippingAddress.street.0"] .label {
    display: none;
}

[name*="billingAddresscashondelivery.street.0"] .label {
    display: none;
}

[name*="billingAddressbanktransfer.street.0"] .label {
    display: none;
}

[name*="billingAddressshared.street.0"] .label {
    display: none;
}

[name*="billingAddresspayu_gateway.street.0"] .label {
    display: none;
}
