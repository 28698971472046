.gift-points-outer {

    border-bottom: solid 1px #dae2e6;
    padding-bottom: 20px;
    margin: 18px 0;
}

.gift-points-box {
    background: #f0f0ed;
    padding: 10px 20px;
    font-size: 14px;

    .points {
        color: $color-green;
        font-weight: bold;
    }
}

.gift-points-login-alert {
    font-size: 11px;
    padding: 10px 20px;
    background: #fdf0d5;
    color: #6f4400;
}
