.am-checkout.-modern {
    margin-bottom: 40px !important;

    .authentication-wrapper {
        margin-top: -61px !important;

        .action-auth-toggle {
            background-color: $color-green;
            border-radius: 0px;
            font-weight: 400;
            font-family: "Oswald";
            text-transform: uppercase;
        }
    }
}

.amcheckout-step-container .amcheckout-title > .amcheckout-icon {
    border-color: $color-green;

    &:before {
        border-color: $color-green;
    }
}

.amcheckout-main-container.-modern .amcheckout-step-container .amcheckout-summary-container .table-totals {
    width: 100%;
}

._amcheckout-selectable-item.-selected,
.amcheckout-step-container .amcheckout-shipping-address .billing-address-details.-selected,
.amcheckout-step-container .amcheckout-shipping-address .shipping-address-details.-selected,
.amcheckout-step-container .shipping-address-items .shipping-address-item.-selected,
.amcheckout-step-container .shipping-address-items .shipping-address-item.selected-item,
.amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method.-selected,
.amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method.-selected,
.amcheckout-step-container .checkout-payment-method .amcheckout-payment-methods .payment-method.-selected,
.amcheckout-step-container .checkout-payment-method .amcheckout-payment-methods .payment-method._active {
    border-color: $color-green;
}

.amcheckout-step-container .amcheckout-button:not(.braintree-googlepay-button):not(.ui-datepicker-trigger),
.amcheckout-step-container button:not(.braintree-googlepay-button):not(.ui-datepicker-trigger) {
    background-color: $color-green;
}
