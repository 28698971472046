/*** MAIN STYLES ***/
.page-header.type16 {
    logo {
        margin: 12px 0;
    }
}

.breadcrumbs {
    li.home {
        a {
            text-indent: -999px;
        }
    }
}

.products-grid .product-item .product-item-details .product-item-name {
    white-space: normal;
}

.sw-megamenu.navigation li.level0.classic .submenu a,
.sw-megamenu.navigation li.level0.fullwidth .submenu a,
.sw-megamenu.navigation li.level0.staticwidth .submenu a {
    white-space: normal;
}

.products-grid .product-item .product-item-details .product-item-name {
    white-space: normal;
}

.sw-megamenu.navigation li.level0.classic .submenu a,
.sw-megamenu.navigation li.level0.fullwidth .submenu a,
.sw-megamenu.navigation li.level0.staticwidth .submenu a {
    white-space: normal;
}

.magento-rma-returns-returns .page-main .page-title-wrapper .page-title,
.magento-rma-returns-view .page-main .page-title-wrapper .page-title,
.magento-rma-guest-returns .page-main .page-title-wrapper .page-title,
.account .page-main .page-title-wrapper .page-title,
[class^="sales-guest-"] .page-main .page-title-wrapper .page-title,
.sales-guest-view .page-main .page-title-wrapper .page-title,
.magento-rma-guest-returns .page-main .page-title-wrapper .page-title,
.page-main > .page-title-wrapper .page-title {
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -0.01em;
    color: #21293c !important;
    margin: 10px 0 15px;
}


.magento-rma-returns-returns .page-main .page-title-wrapper .page-title,
.magento-rma-returns-view .page-main .page-title-wrapper .page-title,
.magento-rma-guest-returns .page-main .page-title-wrapper .page-title,
.account .page-main .page-title-wrapper .page-title,
[class^="sales-guest-"] .page-main .page-title-wrapper .page-title,
.sales-guest-view .page-main .page-title-wrapper .page-title,
.magento-rma-guest-returns .page-main .page-title-wrapper .page-title,
.page-main > .page-title-wrapper .page-title {
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -0.01em;
    color: #21293c !important;
    margin: 10px 0 15px;
}


.magento-rma-returns-returns .page-main .page-title-wrapper .page-title,
.magento-rma-returns-view .page-main .page-title-wrapper .page-title,
.magento-rma-guest-returns .page-main .page-title-wrapper .page-title,
.account .page-main .page-title-wrapper .page-title,
[class^="sales-guest-"] .page-main .page-title-wrapper .page-title,
.sales-guest-view .page-main .page-title-wrapper .page-title,
.magento-rma-guest-returns .page-main .page-title-wrapper .page-title,
.page-main > .page-title-wrapper .page-title {
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -0.01em;
    color: #21293c !important;
    margin: 10px 0 15px;
}

body {
    background-image: url("/pub/media/cerkamed/img/bg.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-attachment: fixed;
}

.page-header {
    background-color: transparent !important;
}

.message.global.cookie {
    background-color: #f0f0ed !important;
}

.stock.unavailable {
    color: #ed5348;
}

.products-grid .product-item .product-item-details .product-item-name {
    min-height: 63px;
}

.product-label.new-label {
    background-color: #ed5348;
}

.page-header.header-newskin .navigation li.level0.fullwidth .submenu li.level1:nth-child(1) > a,
.page-header.header-newskin .navigation li.level0.fullwidth .submenu li.level1:nth-child(2) > a,
.page-header.header-newskin .navigation li.level0.fullwidth .submenu li.level1:nth-child(3) > a {
    color: #d45656;
}
