.gift-order-block {
    .block-content {
        font-size: 1.5rem;

        .gift-points {
            color: $color-green;
            font-size: 2rem;
            font-weight: 600;
        }
    }
}

.gift-order-table-outer {
    height: 360px;
    overflow-y: scroll;
    margin-bottom: 20px;

    .gift-order-table {
        .gift-name {
            font-weight: bold;
            font-size: 1.2rem;
        }

        .gift-points {
            color: $color-green;
            font-weight: bold;
            font-size: 1.2rem;
        }

        input {
            width: 50px;
        }
    }
}

#giftpoints-search {
    margin-bottom: 20px;
}

