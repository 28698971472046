.carousel-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 540px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }

    #banner-slider-carousel {
        .owl-nav {
            button {
                color: $color-green;
                font-size: 96px;
                line-height: 68px;
                align-items: center;
                margin: 0 5px;

                span {
                    display: block;
                    padding: 0 5px;
                    height: 96px;
                }
            }
        }
    }
}
