.block-addresses-default {
    margin-top: 40px;
}

.block-addresses-list {
    margin-bottom: 20px !important;
    @media (min-width: 768px) {
        margin-bottom: 20px !important;
    }
}

.account-nav-content {
    ul.nav {
        border-top: 1px solid $color-border;
        border-left: 1px solid $color-border;
        border-right: 1px solid $color-border;
        li {
            a,
            strong {
                padding: 15px 19px;
                margin: 0 1px;
                font-weight: bold;
                color: $color-product-name;
                display: block;
                text-transform: uppercase;
                font-size: 12px;
                border-bottom: 1px solid $color-border;

                &:before {
                    content: '';
                }

                &:hover {
                    text-decoration: none;
                    color: $color-green;
                    background-color: $color-hover-sidebar-menu;
                }
            }

            strong {
                color: $color-green;
            }
        }
    }
}
