.category-sidebar-cerkamed-layer {
    background-color: #000000;
    background-color: rgba(0, 0, 0, .5);
    opacity: 1;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 998;
    display: none;

    @media (max-width: 768px) {
        display: none;
    }
}

.category-sidebar-sticky-button {
    display: block;

    color: transparent !important;
    position: fixed;
    top: calc(22% - 52px);
    left: -1px;
    width: 51px;
    height: 47px;
    border: #dcdcda solid 1px !important;
    background-color: #f5f5f5;
    text-align: center;
    box-shadow: none;
    border-radius: 0;
    cursor: pointer;
    line-height: 26px;
    z-index: 9;

    &:before {
        content: '\e87b';
        font-family: porto-icons;
        font-size: 20px;
        color: #818181;
        line-height: 45px;
        display: block;
        -webkit-font-smoothing: antialiased;
    }

    @media (min-width: 768px) {
        display: none;
    }
}

.category-sidebar-sticky-reply {
    display: block;

    color: transparent !important;
    position: absolute;
    top: calc(22% - 52px);
    right: -51px;
    width: 51px;
    height: 47px;
    border: #dcdcda solid 1px;
    border-left: none;
    background-color: #f5f5f5;
    text-align: center;
    box-shadow: none;
    border-radius: 0;
    cursor: pointer;
    line-height: 26px;
    z-index: 9;

    &:before {
        content: '\f857';
        font-family: porto-icons;
        font-size: 20px;
        color: #818181;
        line-height: 45px;
        display: block;
        -webkit-font-smoothing: antialiased;
    }

    @media (min-width: 768px) {
        display: none;
    }
}

.category-sidebar-cerkamed {
    position: relative;
    padding: 0;

    @media (max-width: 768px) {
        display: none;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 999;
        width: 300px;
        height: 100vh;
        background: white;
    }

    .scrollable {
        @media (max-width: 768px) {
            height: 100vh;
            overflow-y: scroll;
        }
    }


    > .o-list > li > ul > li:nth-child(1) > a,
    > .o-list > li > ul > li:nth-child(2) > a,
    > .o-list > li > ul > li:nth-child(3) > a {
        color: #d45656;
    }


    h3 {
        padding: 20px 20px 18px;
        margin: 0;
        color: $color-light-gray;
        border-bottom: 1px solid #ededed;
        text-transform: uppercase;
    }

    ul {
        list-style: none;
    }

    .o-list {
        margin: 0px;
        padding: 0px;


        li {
            padding: 0px;
            margin: 0px;
            position: relative;

            > ul {
                padding-top: 0px;

                li {

                    &.active {
                        > ul {
                            //@media (min-width: 768px) {
                            //    display: none;
                            //}
                        }
                    }


                    a {
                        &.is-active {
                            color: $color-green;
                        }
                    }

                    > ul {

                        //@media (min-width: 768px) {
                        //    position: absolute;
                        //    top: 50%;
                        //    transform: translateY(-50%);
                        //    left: calc(100% - 1px);
                        //    width: 350px;
                        //    z-index: 99999;
                        //    background-color: #ffffff;
                        //    border: 1px solid #ededed;
                        //    padding: 20px;
                        //
                        //    //-webkit-box-shadow: 4px 0px 10px 1px rgba(0,0,0,0.05);
                        //    //-moz-box-shadow: 4px 0px 10px 1px rgba(0,0,0,0.05);
                        //    //box-shadow: 4px 0px 10px 1px rgba(0,0,0,0.05);
                        //
                        //    box-shadow: 4px 0 10px -2px rgba(0, 0, 0, 0.25);
                        //}

                        li {
                            //@media (min-width: 768px) {
                            //    border-bottom: none;
                            //}

                            a {
                                font-weight: 600;
                                color: $color-light-gray;
                                //@media (min-width: 768px) {
                                //    padding: 10px 14px;
                                //}
                            }
                        }
                    }
                }
            }

            &:hover {
                > ul {
                    //@media (min-width: 768px) {
                    //    display: block !important;
                    //}
                }
            }


            .expand {
                //.fa-plus {
                //    display: block;
                //}

                //@media (min-width: 768px) {
                //    .fa-plus {
                //        display: none;
                //    }
                //}
            }

            .expanded {
                //.fa-minus {
                //    display: block;
                //}

                //@media (min-width: 768px) {
                //    .fa-minus {
                //        display: none;
                //    }
                //}
            }

            .expand,
            .expanded {
                .fa-angle-right {
                    display: none;
                }

                //@media (min-width: 768px) {
                //    .fa-angle-right {
                //        display: block;
                //    }
                //}
            }

            a {
                padding: 15px 19px;
                margin: 0 1px;
                font-weight: bold;
                color: $color-product-name;
                display: block;
                text-transform: uppercase;
                font-size: 12px;

                &.is-current {
                    color: $color-green;
                }

                &:hover {
                    text-decoration: none;
                    background-color: $color-hover-sidebar-menu;
                    color: $color-green;
                }

                + span {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 15px 15px;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .sidebar-home {
        &:before {
            content: '';
            position: absolute;
            left: 15px;
            right: 15px;
            border: solid 1px #dae2e6;
            top: 0;
            bottom: 0;
            border-radius: 2px;
        }
    }
}

.sidebar .sidebar-filterproducts {
    border-bottom: none !important;
    padding-bottom: 0 !important;
}

