/*** PRODUCT DETAIL PAPGE ***/
.product.description {
    ul {
        list-style: disc;
    }
}

.catalog-product-view {
    .sidebar {
        .custom-block-1 > div h3 {
            margin-left: 0;
        }
    }
}

.sidebar {
    .custom-block.custom-block-1 > div p {
        margin-left: 0;
    }
}

.product-info-main .product-info-price .price-box,
.product-item-details .price-box {
    display: flex;
    flex-direction: column;
}

.price-including-tax + .price-excluding-tax:before {
    content: 'Netto: ' !important;
}

